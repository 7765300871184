/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-return */
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'
import eventApi from '_api/event'
import groupApi from '_api/group'
import orderApi from '_api/order'
import serviceApi from '_api/service'
import { clearDeliveryDetail } from '_redux/modules/delivery'
import { clearPastPurchasedMember } from '_redux/modules/group'
import {
  clearDataPurchaseDetail,
  clearDataPurchaseDetailByEid,
  clearMyOrder,
} from '_redux/modules/order'
import { DOMAIN, STATUS_PAID } from '_utils/constant'
import { getDomain, getUserInfo, setDomain } from '_utils/localData'

const GET_LIST_EVENTS = 'GET_LIST_EVENTS'
const GET_LIST_EVENT_GROUP_ID_BY_HOST = 'GET_LIST_EVENT_GROUP_ID_BY_HOST'
const GET_LIST_SCHEDULED_EVENTS = 'GET_LIST_SCHEDULED_EVENTS'
const DELETE_LIST_SCHEDULED_EVENTS = 'DELETE_LIST_SCHEDULED_EVENTS'
const GET_LIST_SCHEDULED_EVENTS_ERROR = 'GET_LIST_SCHEDULED_EVENTS_ERROR'
const GET_MY_EVENTS = 'GET_MY_EVENTS'
const GET_ALL_EVENTS_OPENING_REQUEST = 'GET_ALL_EVENTS_OPENING_REQUEST'
const GET_ALL_EVENTS_OPENING_SUCCESS = 'GET_ALL_EVENTS_OPENING_SUCCESS'
const GET_ALL_EVENTS_OPENING_FAIL = 'GET_ALL_EVENTS_OPENING_FAIL'
const GET_SHARE_EVENT_CODE = 'GET_SHARE_EVENT_CODE'
const CLEAR_EVENT = 'CLEAR_EVENT'
const DELETE_EVENT = 'DELETE_EVENT'
const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID'
const GET_EVENT_AND_ORDER_BUY_EID = 'GET_EVENT_AND_ORDER_BUY_EID'
const CREATE_EVENT = 'CREATE_EVENT'
const UPDATE_EVENT = 'UPDATE_EVENT'
const UPDATE_STATUS_EVENT = 'UPDATE_STATUS_EVENT'
const UPDATE_EVENT_ORDER = 'UPDATE_EVENT_ORDER'
const UPDATE_STATUS_ORDER = 'UPDATE_STATUS_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER'
const UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER = 'UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER'
const CLEAR_EVENT_ORDER = 'CLEAR_EVENT_ORDER'
const GET_COMBINED_PAYMENT = 'GET_COMBINED_PAYMENT'
const CLEAR_MY_EVENTS = 'CLEAR_MY_EVENTS'
const CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS'
const GET_EVENTS_CLOSE_PER_MONTH_BY_HOST = 'GET_EVENTS_CLOSE_PER_MONTH_BY_HOST'
const GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST = 'GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST'
const GET_LIST_EVENT_AND_DELIVERY = 'GET_LIST_EVENT_AND_DELIVERY'
const GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID = 'GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID'

const initialState = {
  [DEFAULT_KEY]: null,
  allEvents: [],
  myEvents: [],
  newGroup: null,
  eventById: null,
  eventAndOrderById: [],
  callNewAPIListOrder: false,
  loadingAllEventsOpening: true,
  errorAllEventsOpening: null,
  errorGetEventAndOrder: null,
  allScheduleEvent: [],
  allScheduleEventByGroupId: [],
  errorAllScheduleEvent: null,
  listCombinedPayments: [],
  listEventGroupIdByHost: null,
  listEventsClosePerMonthByHost: null,
  listEventsCollectionPerMonthByHost: null,
  listEventAndDelivery: null,
}

export const getAllScheduleEventByGroupId = (groupId) => async (dispatch) => {
  try {
    let allScheduleEventByGroupId = []
    const { msgResp: newAllScheduleEvent } = await eventApi.listMyScheduledEvents()
    allScheduleEventByGroupId = newAllScheduleEvent?.filter((item) => item.groupId === groupId)
    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID,
      payload: { allScheduleEventByGroupId },
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getListEventAndDelivery = (groupId) => async (dispatch) => {
  try {
    const response = await eventApi.getListEventAndDelivery(groupId)
    const listEventAndDelivery = { ...response.msgResp }

    dispatch({
      type: GET_LIST_EVENT_AND_DELIVERY,
      payload: { listEventAndDelivery },
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getEventGroupIdByHost = (groupId, date) => async (dispatch) => {
  const userInfo = getUserInfo()
  let listEventGroupIdByHost = []
  try {
    const response = await eventApi.getListEventsByGroupId(groupId, date)
    if (response.msgResp) {
      const eventByGroupIdByHost = response.msgResp.filter(
        (_event) => _event.createdUserId === userInfo.id
      )
      listEventGroupIdByHost = eventByGroupIdByHost
    }
    dispatch({
      type: GET_LIST_EVENT_GROUP_ID_BY_HOST,
      payload: { listEventGroupIdByHost },
    })
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const callNewListOrder = () => async (dispatch) => {
  dispatch({
    type: GET_EVENT_AND_ORDER_BUY_EID,
    payload: { callNewAPIListOrder: true },
  })
}

export const getAllEventsOpening = async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_EVENTS_OPENING_REQUEST,
    payload: { loadingAllEventsOpening: true },
  })
  try {
    const { event } = getState()
    const { allEvents } = event

    // Not call API from server
    if (allEvents && allEvents.length > 0) {
      dispatch({
        type: GET_ALL_EVENTS_OPENING_SUCCESS,
        payload: { loadingAllEventsOpening: false, allEvents },
      })
      return
    }

    // Call API from server
    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }

    const { msgResp: newAllEventsOpening } = await eventApi.getListEventsOpening(domainLocal)
    dispatch({
      type: GET_ALL_EVENTS_OPENING_SUCCESS,
      payload: { loadingAllEventsOpening: false, allEvents: newAllEventsOpening },
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_EVENTS_OPENING_FAIL,
      payload: { loadingAllEventsOpening: false, errorAllEventsOpening: error },
    })
  }
}

export const getScheduledEvent = async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allScheduleEvent } = event

    // Not call API from server
    if (allScheduleEvent && allScheduleEvent.length > 0) {
      return
    }

    // Call API from server
    let domainLocal = getDomain(DOMAIN)
    const parser = document.createElement('a')
    parser.href = process.env.REACT_APP_APP_URL

    if (!domainLocal && DOMAIN !== parser.hostname) {
      const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
      const { groupId } = msgResp

      domainLocal = groupId
      setDomain(DOMAIN, groupId)
    }
    const { msgResp: newAllScheduleEvent } = await eventApi.listMyScheduledEvents(domainLocal)

    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: { allScheduleEvent: newAllScheduleEvent },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: { errorAllScheduleEvent: error },
    })
  }
}

export const clearScheduledEvent = (dispatch) => {
  dispatch({
    type: DELETE_LIST_SCHEDULED_EVENTS,
    payload: { allScheduleEvent: [] },
  })
}

export const deleteScheduledEvent = (id) => (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allScheduleEvent } = event

    const newAllScheduleEvent = allScheduleEvent

    const index = newAllScheduleEvent.findIndex((item) => item.id === id)

    if (index !== -1) {
      newAllScheduleEvent.splice(index, 1)
    }

    dispatch({
      type: GET_LIST_SCHEDULED_EVENTS,
      payload: {
        allScheduleEvent: [...newAllScheduleEvent],
      },
    })
  } catch (error) {}
}

export const getMyEvents = async (dispatch, getState) => {
  try {
    const { event, group } = getState()
    const { myEvents, allEvents } = event
    const { myGroups } = group
    const userInfo = getUserInfo()
    if (myEvents?.length) {
      return
    }
    if (allEvents?.length > 0 && myEvents === null) {
      const newMyEvents = allEvents.filter((_event) => _event.createdUserId === userInfo.id)
      dispatch({ type: GET_MY_EVENTS, payload: { myEvents: newMyEvents } })
    } else {
      let events = []

      let domainLocal = getDomain(DOMAIN)
      const parser = document.createElement('a')
      parser.href = process.env.REACT_APP_APP_URL

      if (!domainLocal && DOMAIN !== parser.hostname) {
        const { msgResp } = await serviceApi.getGroupIdByDomainName(DOMAIN, STATUS_PAID)
        const { groupId } = msgResp

        domainLocal = groupId
        setDomain(DOMAIN, groupId)
      }

      const { msgResp: listEvents } = await eventApi.getListMyEvents(domainLocal)
      for (let i = 0; i < listEvents.length; i++) {
        const _event = listEvents[i]
        orderApi.getListOrdersByEventId(_event.id).then(async (res) => {
          const newEvent = {
            ..._event,
            orders: res.msgResp,
          }
          if (!myGroups) {
            const { msgResp: groupInfo } = await groupApi.getById(_event.groupId)
            newEvent.groupName = groupInfo.name
          } else {
            events = events.map((item) => {
              item.groupName = myGroups.filter((l) => l.gid === item.groupId)[0]?.name
              return item
            })
          }
          events = [...events, newEvent]

          const newMyEvents = events.filter((item) => item.createdUserEmail === userInfo.email)
          dispatch({
            type: GET_MY_EVENTS,
            payload: { myEvents: newMyEvents },
          })
        })
      }
      const newMyEvents = listEvents.filter((_event) => _event.createdUserId === userInfo.id)
      dispatch({
        type: GET_MY_EVENTS,
        payload: { myEvents: [...newMyEvents] },
      })
    }
  } catch (error) {
    throw new Error(error.msgResp)
  }
}

export const getShareEventCode = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents } = event
    const { msgResp } = await eventApi.getShareEventCode(eventId)
    const newMyEvents = myEvents
    const indexInMyEvents = newMyEvents.filter((item) => item.id === eventId)
    if (indexInMyEvents !== -1) {
      newMyEvents[indexInMyEvents].shareCode = msgResp.shareCode
      dispatch({
        type: GET_SHARE_EVENT_CODE,
        payload: { myEvents: [...newMyEvents] },
      })
    }
  } catch (error) {}
}

export const clearGroup = () => ({
  type: CLEAR_EVENT,
})

export const updateDataEventAndOrder = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventAndOrderById } = event
    const newEventAndOrderById = eventAndOrderById
    const index = newEventAndOrderById.findIndex((item) => item.id === eventId)
    if (index !== -1) {
      newEventAndOrderById[index].updated = true
    }
    dispatch({
      type: UPDATE_EVENT_ORDER,
      payload: { eventAndOrderById: newEventAndOrderById },
    })
  } catch (error) {}
}

export const clearEventAndOrder = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_ORDER,
    payload: { eventAndOrderById: [] },
  })
}

export const clearMyEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MY_EVENTS,
    payload: { myEvents: [] },
  })
}

export const clearAllEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS,
    payload: { allEvents: [] },
  })
}

export const updateEventWhenOrder = (newOrder) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { eid, details } = newOrder

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    const indexEvent = allEvents?.findIndex((item) => item.id === eid)
    if (allEvents.length && indexEvent !== -1) {
      allEvents[indexEvent].totalOrderCount += count
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eid)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount += count
    }

    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateTotalOrderCountWhenCancelOrder = (orderDetail) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { event: eventDetail, productOrder } = orderDetail
    const totalOrderCount = productOrder?.allProductPerOrder.reduce((a, b) => a + b.pQuantity, 0)

    const indexInAllEvents = allEvents?.findIndex((item) => item.id === eventDetail.id)
    if (allEvents.length && indexInAllEvents !== -1) {
      allEvents[indexInAllEvents].totalOrderCount -= totalOrderCount
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eventDetail.id)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount -= totalOrderCount
    }
    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateTotalOrderCountWhenUpdateOrder = (orderDetail) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { allEvents, myEvents } = event
    const { eid, details, initTotalOrderCount } = orderDetail

    let count = 0
    for (const key in details) {
      if (Object.hasOwnProperty.call(details, key)) {
        count += details[key][0].quantity
      }
    }

    count -= initTotalOrderCount

    const indexEvent = allEvents?.findIndex((item) => item.id === eid)
    if (allEvents.length && indexEvent !== -1) {
      allEvents[indexEvent].totalOrderCount += count
    }

    const indexInMyEvents = myEvents?.findIndex((item) => item.id === eid)
    if (myEvents.length && indexInMyEvents !== -1) {
      myEvents[indexInMyEvents].totalOrderCount += count
    }
    dispatch({
      type: UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER,
      payload: {
        allEvents: [...allEvents],
        myEvents: [...myEvents],
      },
    })
  } catch (error) {}
}

export const updateOrderStatusOfOrderInAllEventAndOrder =
  (eventId, ref, status, buyerStatus = null) =>
  async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { listEventAndDelivery } = event
      const newEventAndOrder = listEventAndDelivery.eventAndOrders
      const index = newEventAndOrder.findIndex((item) => item.id === eventId)
      if (index !== -1) {
        const newOrder = newEventAndOrder[index].orders
        for (let indexOrder = 0; indexOrder < newOrder.length; indexOrder++) {
          if (newOrder[indexOrder].ref === ref) {
            newOrder[indexOrder].status = status
            newOrder[indexOrder].buyerStatus = buyerStatus
          }
        }
        newEventAndOrder[index] = {
          ...newEventAndOrder[index],
          orders: newOrder,
        }
      }
      dispatch(clearDataPurchaseDetailByEid(eventId))
      dispatch(clearMyOrder())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrder: newEventAndOrder,
          callNewAPIListOrder: true,
        },
      })
    } catch (error) {}
  }

export const updateOrderStatus =
  (eventId, ref, status, buyerStatus = null) =>
  async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { eventAndOrderById } = event
      const newEventAndOrderById = eventAndOrderById
      const index = newEventAndOrderById.findIndex((item) => item.id === eventId)
      if (index !== -1) {
        const newOrder = newEventAndOrderById[index].orders
        for (let indexOrder = 0; indexOrder < newOrder.length; indexOrder++) {
          if (newOrder[indexOrder].ref === ref) {
            newOrder[indexOrder].status = status
            newOrder[indexOrder].buyerStatus = buyerStatus
          }
        }
        newEventAndOrderById[index] = {
          ...newEventAndOrderById[index],
          orders: newOrder,
        }
      }
      dispatch(clearDataPurchaseDetailByEid(eventId))
      dispatch(clearMyOrder())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrderById: newEventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (error) {}
  }

export const getEventAndOrder = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventAndOrderById = [], callNewAPIListOrder = false } = event
    const indexOrder = eventAndOrderById.findIndex((item) => item.id === eventId)
    if (indexOrder !== -1) {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById,
          errorGetEventAndOrder: null,
        },
      })
      if (!callNewAPIListOrder) {
        return
      }
    }
    let newEventAndOrderById = {}
    let eventAndOrderByIdReturn = {}
    const { msgResp: _event } = await eventApi.getEventDetail({
      eid: eventId,
      isGetDeliveryZones: true,
    })
    const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(eventId)

    newEventAndOrderById = {
      ..._event,
      orders: _orderByEid,
    }

    const orderNo = new Set()
    const orderRef = new Set()
    if (_orderByEid && _orderByEid.length > 0) {
      for (let index = 0; index < _orderByEid.length; index++) {
        orderNo.add(_orderByEid[index].eOrderNo)
        orderRef.add(_orderByEid[index].ref)
      }
      if (orderNo.size !== orderRef.size || Math.max(...orderNo) !== orderRef.size) {
        orderApi.updateOrderNo(eventId).then((res) => {
          const { msgCode } = res
          if (msgCode && Number(msgCode) % 100 === 0) {
            orderApi.getListOrdersByEventId(eventId).then((res1) => {
              const { msgResp } = res1
              newEventAndOrderById = {
                ..._event,
                orders: msgResp,
              }
            })
          }
        })
      }
    }
    if (callNewAPIListOrder) {
      eventAndOrderById[indexOrder] = newEventAndOrderById
      eventAndOrderByIdReturn = eventAndOrderById
    } else {
      eventAndOrderByIdReturn = [...eventAndOrderById, newEventAndOrderById]
    }

    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: {
        eventAndOrderById: eventAndOrderByIdReturn,
        callNewAPIListOrder: false,
        errorGetEventAndOrder: null,
      },
    })
  } catch (e) {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: { errorGetEventAndOrder: e },
    })
  }
}

export const getOrderCombinedPayment = (combinedPaymentRef) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { listCombinedPayments = [], eventAndOrderById = [] } = event

    const listEventAndOrder = []

    const exitOrderCombinedPayment = listCombinedPayments.findIndex(
      (item) => item.combinedPaymentRef === combinedPaymentRef
    )

    let orderCombinedPayment = []
    const uniqueOrderCombinedPayment = []

    if (exitOrderCombinedPayment === -1) {
      const { msgResp } = await eventApi.listInvoicesCombinedPayment(combinedPaymentRef)
      const { orders } = msgResp

      // eslint-disable-next-line no-unused-vars
      orderCombinedPayment = orders.map(({ ref, eid, eTitle, ...rest }) => ({ ref, eid, eTitle }))

      orderCombinedPayment.map((x) =>
        uniqueOrderCombinedPayment.filter((a) => a.ref === x.ref && a.eid === x.eid).length > 0
          ? null
          : uniqueOrderCombinedPayment.push(x)
      )
    } else {
      // eslint-disable-next-line prefer-destructuring
      orderCombinedPayment = listCombinedPayments[exitOrderCombinedPayment].orderCombinedPayment
    }

    if (uniqueOrderCombinedPayment.length) {
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: {
          listCombinedPayments: [
            ...listCombinedPayments,
            { combinedPaymentRef, orderCombinedPayment: uniqueOrderCombinedPayment },
          ],
        },
      })
    }

    for (const element of orderCombinedPayment) {
      const { eid } = element
      const exitEvenAndOrder = eventAndOrderById.findIndex((item) => item.id === eid)
      const exitListEO = listEventAndOrder.findIndex((item) => item.id === eid)

      if (exitEvenAndOrder === -1 && exitListEO === -1) {
        const { msgResp: _event } = await eventApi.getEventById(eid)
        const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(eid)

        const newEventAndOrderById = {
          ..._event,
          orders: _orderByEid,
        }
        listEventAndOrder.push(newEventAndOrderById)
      }
    }
    if (listEventAndOrder.length) {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById: [...eventAndOrderById, ...listEventAndOrder],
          callNewAPIListOrder: false,
          errorGetEventAndOrder: null,
        },
      })
    }
  } catch (e) {}
}

export const getListEventOrder = (listEid) => async (dispatch, getState) => {
  const { event } = getState()
  const { eventAndOrderById = [] } = event
  const listEventAndOrder = []

  for (const _eid of listEid) {
    const exitEventOrder = eventAndOrderById.findIndex((item) => item.id === _eid)
    const exitListEO = listEventAndOrder.findIndex((item) => item.id === _eid)

    if (exitEventOrder === -1 && exitListEO === -1) {
      const { msgResp: _event } = await eventApi.getEventById(_eid)
      const { msgResp: _orderByEid } = await orderApi.getListOrdersByEventId(_eid)

      const newEventAndOrderById = {
        ..._event,
        orders: _orderByEid,
      }
      listEventAndOrder.push(newEventAndOrderById)
    }
  }
  if (listEventAndOrder.length) {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: {
        eventAndOrderById: [...eventAndOrderById, ...listEventAndOrder],
        callNewAPIListOrder: false,
        errorOrderDetail: null,
      },
    })
  }
}

export const getListCombinedPayment = (listCombinedPaymentRef) => async (dispatch, getState) => {
  const { event } = getState()
  const { listCombinedPayments = [] } = event

  const newListCombinedPayment = []
  const listEid = []

  for (const combinedRef of listCombinedPaymentRef) {
    let orderCombinedPayment = []
    const exitCombinedPayment = listCombinedPayments.findIndex(
      (item) => item.combinedPaymentRef === combinedRef
    )

    const exitList = newListCombinedPayment.findIndex(
      (item) => item.combinedPaymentRef === combinedRef
    )

    if (exitCombinedPayment === -1 && exitList === -1) {
      const { msgResp } = await eventApi.listInvoicesCombinedPayment(combinedRef)
      const { orders, deliveryBookings } = msgResp
      // eslint-disable-next-line no-unused-vars
      orderCombinedPayment = orders.map(({ ref, eid, eTitle, ...rest }) => ({ ref, eid, eTitle }))

      const uniqueOrderCombinedPayment = []

      orderCombinedPayment.map((x) =>
        uniqueOrderCombinedPayment.filter((a) => a.ref === x.ref && a.eid === x.eid).length > 0
          ? null
          : uniqueOrderCombinedPayment.push(x)
      )

      orderCombinedPayment.map((x) =>
        listEid.filter((a) => a === x.eid).length > 0 ? null : listEid.push(x.eid)
      )

      newListCombinedPayment.push({
        combinedPaymentRef: combinedRef,
        orderCombinedPayment: uniqueOrderCombinedPayment,
        deliveryCombinedPayment: deliveryBookings,
      })
    }
  }

  if (newListCombinedPayment.length) {
    dispatch({
      type: GET_COMBINED_PAYMENT,
      payload: {
        listCombinedPayments: [...listCombinedPayments, ...newListCombinedPayment],
      },
    })
    await dispatch(getListEventOrder(listEid))
  }
}

export const updateOrderCombinedPayment =
  (combinedPaymentRef, status, buyerStatus) => async (dispatch, getState) => {
    try {
      const { event } = getState()
      const { listCombinedPayments = [], eventAndOrderById = [] } = event

      const exitOrderCombinedPayment = listCombinedPayments.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      let orderCombinedPayment = []
      if (exitOrderCombinedPayment !== -1) {
        // eslint-disable-next-line prefer-destructuring
        orderCombinedPayment = listCombinedPayments[exitOrderCombinedPayment].orderCombinedPayment
        // get EventAndOrder relate
        orderCombinedPayment.forEach(async (element) => {
          const { eid, ref } = element
          const exitEvenAndOrder = eventAndOrderById.findIndex((item) => item.id === eid)
          if (exitEvenAndOrder !== -1) {
            const newOrder = eventAndOrderById[exitEvenAndOrder].orders
            for (let indexOrder = 0; indexOrder < newOrder.length; indexOrder++) {
              if (newOrder[indexOrder].ref === ref) {
                newOrder[indexOrder].status = status
                newOrder[indexOrder].buyerStatus = buyerStatus
              }
            }
            eventAndOrderById[exitEvenAndOrder] = {
              ...eventAndOrderById[exitEvenAndOrder],
              orders: newOrder,
            }
          }
        })
      }
      dispatch(clearDataPurchaseDetail())
      dispatch(clearMyOrder())
      dispatch(clearDeliveryDetail())
      dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          eventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (e) {}
  }

export const clearListCombinedPayment = () => (dispatch) => {
  try {
    dispatch({
      type: GET_COMBINED_PAYMENT,
      payload: { listCombinedPayments: [] },
    })
  } catch (e) {}
}

export const getEventById = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents } = event
    let eventNeed = {}
    const indexInMyEvents = myEvents?.filter((item) => item.id === eventId)
    if (indexInMyEvents) {
      eventNeed = indexInMyEvents[0]
    } else {
      await eventApi.getEventById(eventId).then(({ msgResp }) => {
        eventNeed = msgResp
      })
    }
    dispatch({
      type: GET_EVENT_BY_ID,
      payload: { eventById: eventNeed },
    })
  } catch (error) {}
}

export const createEvent = (data) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents } = event
    const newMyEvents = myEvents
    const newAllEvents = allEvents
    const { msgResp } = await orderApi.getListOrdersByEventId(data.id)
    const newEvent = {
      ...data,
      orders: msgResp,
    }
    newMyEvents.unshift(newEvent)
    newAllEvents.unshift(newEvent)
    dispatch({
      type: CREATE_EVENT,
      payload: { allEvents: [...newAllEvents], myEvents: [...newMyEvents] },
    })
  } catch (error) {}
}

export const updateEvent = (data) => (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents } = event
    // await eventApi.updateEvent(data)
    const newMyEvents = myEvents
    const newAllEvents = allEvents

    const indexInMyEvents = newMyEvents.findIndex((item) => item.id === data.id)
    const indexInAllEvent = newAllEvents.findIndex((item) => item.id === data.id)

    if (indexInMyEvents !== -1) {
      newMyEvents[indexInMyEvents] = { ...newMyEvents[indexInMyEvents], ...data }
    } else {
      newMyEvents.push(data)
    }

    if (indexInAllEvent !== -1) {
      newAllEvents[indexInAllEvent] = { ...newAllEvents[indexInAllEvent], ...data }
    } else {
      newAllEvents.push(data)
    }
    dispatch({
      type: UPDATE_EVENT,
      payload: { myEvents: [...newMyEvents] },
    })
  } catch (error) {}
}
export const updateStatusEvent = () => (dispatch) => {
  dispatch({
    type: UPDATE_STATUS_EVENT,
    payload: { allEvents: [] },
  })
}

export const deleteEvent = (id) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { myEvents, allEvents, eventAndOrderById } = event

    const newMyEvents = myEvents
    const newAllEvents = allEvents
    const newEventAndOrderById = eventAndOrderById

    const indexInMyEvents = newMyEvents.findIndex((item) => item.id === id)
    const indexInAllEvent = newAllEvents.findIndex((item) => item.id === id)
    const indexInEventAndOrderById = newEventAndOrderById.findIndex((item) => item.id === id)

    if (indexInMyEvents !== -1) {
      newMyEvents.splice(indexInMyEvents, 1)
    }

    if (indexInAllEvent !== -1) {
      newAllEvents.splice(indexInAllEvent, 1)
    }
    if (indexInEventAndOrderById !== -1) {
      newEventAndOrderById.splice(indexInEventAndOrderById, 1)
    }
    dispatch(clearPastPurchasedMember())
    dispatch({
      type: DELETE_EVENT,
      payload: {
        myEvents: [...newMyEvents],
        allEvents: [...newAllEvents],
        eventAndOrderById: [...newEventAndOrderById],
      },
    })
  } catch (error) {}
}

export const getEventsClosePerMonthByHost = (data, groupId) => async (dispatch) => {
  let listEventsClosePerMonthByHost = []

  try {
    const response = await eventApi.getEventsClosePerMonthByHost(data, groupId)
    if (response?.msgCode) {
      listEventsClosePerMonthByHost = response.msgResp
    }
    dispatch({
      type: GET_EVENTS_CLOSE_PER_MONTH_BY_HOST,
      payload: {
        listEventsClosePerMonthByHost,
      },
    })
  } catch (error) {}
}

export const getEventsCollectionPerMonthByHost = (data, groupId) => async (dispatch) => {
  let listEventsCollectionPerMonthByHost = []

  try {
    const response = await eventApi.getEventsCollectionPerMonthByHost(data, groupId)
    if (response?.msgCode) {
      listEventsCollectionPerMonthByHost = response.msgResp
    }
    dispatch({
      type: GET_EVENTS_CLOSE_PER_MONTH_BY_HOST,
      payload: {
        listEventsCollectionPerMonthByHost,
      },
    })
  } catch (error) {}
}

const event = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_EVENTS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload,
      }
    }
    case GET_MY_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_EVENT_GROUP_ID_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_SCHEDULED_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_SCHEDULED_EVENTS_ERROR: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case DELETE_LIST_SCHEDULED_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENT_AND_ORDER_BUY_EID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENT_BY_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_SHARE_EVENT_CODE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_STATUS_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CREATE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_EVENT: {
      return {
        ...state,
        newGroup: null,
      }
    }
    case DELETE_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_EVENT_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_EVENT_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_MY_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLEAR_ALL_EVENTS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_OPENING_REQUEST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_OPENING_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_ALL_EVENTS_OPENING_FAIL: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_CANCEL_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_TOTAL_ORDER_COUNT_WHEN_UPDATE_ORDER: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UPDATE_STATUS_EVENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_COMBINED_PAYMENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENTS_CLOSE_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_EVENTS_COLLECTION_PER_MONTH_BY_HOST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_EVENT_AND_DELIVERY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GET_LIST_SCHEDULED_EVENTS_BY_GROUP_ID: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default event
